import Gallery, { GalleryItem } from "../components/ConceptGallery";
import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/Projects.css'

const sunIcon = "https://gahr.box.com/shared/static/b9ptg669c27ps9aoj482jo26esxvzant.png"


const galleryImages = [
  { caption: "View Looking Southwest towards the Opera House Illustrating Conceptual Improvements", url: "https://gahr.box.com/shared/static/ck0mc9bi6bsmf9zcsqs55blmu8be0ck6.png", alt: "View Looking Southwest towards the Opera House Illustrating Conceptual Improvements" }
];


function ProjectsSunValleyVillage() {
  return (
    <div className='projects'>
      <div className="projects-header-sunvalleyvillage">
        <HeaderSubPages />
      </div>
      <div className="projects-subheader">
        <h2>Sun Valley Village Master Plan</h2>
        <div className="main-text">
          <p>As outlined in the Sun Valley Village Master Plan, continuous improvement is essential for Sun Valley Resort to maintain its excellence in guest experience. Many thoughtful Village improvements have been completed in recent years, including the renovation of the Sun Valley Lodge and Sun Valley Inn, and the creation of the Pavilion. The aim of the Sun Valley Village Master Plan is to sustain scenic beauty, a sense of community and a world renowned year round resort to make the City of Sun Valley a highly desirable place to live, work, play and visit.</p>
        </div>
      </div>
      <div className='hero-quote'>
        <div className='hero-quote-text'>
          <h2 className='quote1'>"</h2>
          <p>The concepts in this plan identify much-needed improvements which will invigorate the existing Village, while honoring the unspoiled character and natural beauty that have long been the most cherished aspects of Sun Valley Village.</p>
          <h2 className='quote2'>"</h2>
        </div>
        <h4>– Pete Sonntag, Vice President and General Manager of Sun Valley Resort</h4>
      </div>
      <Gallery>
        <GalleryItem><img src={galleryImages[0].url} alt={galleryImages[0].alt}></img><p className="concept-gallery-caption">{galleryImages[0].caption}</p></GalleryItem>
      </Gallery>
      <div className="project-highlights-container">
        <div className='project-highlights' id="progress">
          <ul>
            <li><span className="bold">Preserved Architectural Character.</span> Enhancement of existing building facades to better suit contemporary retail needs, taking cues from the Village’s original Union Pacific era “Tyrolean” style architecture.</li>
            <li><span className="bold">Pedestrian Prioritization.</span> A goal of the master plan is to reinforce the walkability, connectivity and accessibility of existing and future amenities within the Village to create a more pedestrian-oriented open space experience within the Village Area.</li>
            <li><span className="bold">Circulation Improvements.</span> Considerations will be made for all types of transit – public, mass, bicycle, and vehicle – to improve efficiency and safety for guests accessing the Sun Valley Village area. </li>
            <li><span className="bold">Expanded Offerings.</span> The Master Plan will include new food & beverage, retail, meeting space and lodging offerings to better support resort guests and local residents.</li>
          </ul>
          <div className="project-highlights-image">
            <img src={sunIcon} alt="sun valley sun icon"></img>
          </div>
        </div>
      </div>
      
      <div className="chairlift-stats">
        <h2>Overview of Areas of the Project</h2>
        <div className="sidebyside-container">
          <div className="sidebyside-text">
            <h3>Near and Long Term Improvements Contemplated</h3>
            <p>The Sun Valley Village Master Plan provides a framework for making architectural and landscape improvements to the Village. Improvements considered include: </p>
            <ul>
              <li>Village Parking Lot Renovations</li>
              <li>Renovation or Replacement of Existing Village Buildings</li>
              <li>Circulation Enhancements</li>
              <li>Renovations to the Olympic Pool and Tennis Complex</li>
              <li>New Food & Beverage and Retail Offerings </li>
              <li>Sun Valley Inn Expansion</li>
              <li>Meeting Facilities Expansion</li>
              <li>Additional Lodging Offerings</li>
            </ul>
          </div>
          <div className="sidebyside-image">
            <img src="https://gahr.box.com/shared/static/52yjwl41vmiutmn6r4ol30b6xd99n0u9.png" alt="sun valley village aerial view"></img>
          </div>
        </div>
        <a className="svv-view-master-button" href='https://gahr.box.com/v/sun-valley-village-master-plan' target="_blank" rel="noreferrer">VIEW VILLAGE MASTER PLAN</a>
        <div className="button-bottom-margin"></div>
        <h2>FAQs</h2>
        <div className="faq-container">
          <div className="faq-text">
            <h3>What prompted Sun Valley Resort to do a Master Plan at this time?</h3>
            <p>The City of Sun Valley 2015 Comprehensive Plan identifies the Sun Valley Village as a significant and prominent area requiring a Master Plan that ensures the goals and objectives of the Comprehensive Plan are achieved. This Master Plan establishes a guiding vision for future improvements to The Village while fulfilling the plan requirement. </p>
            <h3>Does approval of this Master Plan authorize specific projects?</h3>
            <p>Approval of this plan will establish a guiding vision for near-term enhancements and longer-term additions to The Village. Specific projects will require future design review and approval by the City of Sun Valley.</p>
            <h3>What is the relationship between the Comprehensive Plan and the Master Plan?</h3>
            <p>The City of Sun Valley 2015 Comprehensive Plan serves as a guiding document for the development and redevelopment for the City of Sun Valley. The Sun Valley Village Master Plan establishes a guiding vision for the Sun Valley Resort Village core area.</p>
            <h3>What is the vision of the Master Plan?</h3>
            <p>The Village Master Plan is to preserve the Sun Valley Village’s special sense of place and to draw upon its natural setting and extraordinary history to guide future improvements. </p>
            <h3>What improvements are contemplated?</h3>
            <p>The Master Plan presents nearer-term improvements, including Village parking lot updates, renovation or replacement of existing buildings, circulation enhancements, renovation of the Olympic Pool and Tennis Complex, and new food and beverage and retail offerings. Longer-term concepts include expansion of the Sun Valley Inn, meeting facility expansion, back of house improvements, additional lodging and open space, and Moritz Road relocation.</p>
            <h3>What is the timeline for any projects or Village enhancements?</h3>
            <p>The scope of improvements identified in the Master Plan extend 15-20 years or more. The first projects will be exterior enhancements to existing Village buildings. More substantial renovations are identified for Pete Lane’s, the Boiler Room, the Opera House, and most other Village buildings. The Village Parking Lot and the Olympic Pool and Tennis complex are also identified for improvements, but no specific target dates have been established.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSunValleyVillage;
