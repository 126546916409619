import './App.css';

import { Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Layout from './components/Layout.jsx'
import Home from './screens/Home.jsx'
import ProjectsWarmSprings from './screens/Projects-WarmSprings.jsx'
import ProjectsSeattleRidge from './screens/Projects-SeattleRidge.jsx'
import ProjectsSunValleyVillage from './screens/Projects-SunValleyVillage.jsx'
import ProgressWarmSprings from './screens/Progress-WarmSprings.jsx'
import ProgressSeattleRidge from './screens/Progress-SeattleRidge.jsx'
import History from './screens/History.jsx'
import Future from './screens/Future.jsx'

const tagManagerArgs = {
  gtmId: 'GTM-TWJ7PN'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Layout>
      <Routes>
        <Route
          exact path="/"
          element={<Home />}
        />
        <Route
          path="/projects/warmsprings"
          element={<ProjectsWarmSprings />}
        />
        <Route
          path="/projects/seattleridge"
          element={<ProjectsSeattleRidge />}
        />
        <Route
          path="/projects/sunvalleyvillage"
          element={<ProjectsSunValleyVillage />}
        />
        <Route
          path="/progress/warmsprings"
          element={<ProgressWarmSprings />}
        />
        <Route
          path="/progress/seattleridge"
          element={<ProgressSeattleRidge />}
        />
        <Route
          path="/history"
          element={<History />}
        />
        <Route
          path="/future"
          element={<Future />}
        />
      </Routes>
    </Layout>
  );
}

export default App;
