import IGFeed from "../components/IGFeed";
import '../assets/css/Home.css'
import { useState } from 'react'


const sunIcon = "https://gahr.box.com/shared/static/b9ptg669c27ps9aoj482jo26esxvzant.png"
const titleImage = "https://gahr.box.com/shared/static/1eo1o7diqb6j8ik3wuazacptpa9y5two.png"
const homeVideo = "https://gahr.box.com/shared/static/xe6evrid4n6dgsflh0v89uiglhfif4dv.mov#"
const projectWS = "https://gahr.box.com/shared/static/06cy67kfwod7f37yxs4tkt77l8nywxrn.png"
const projectSR = "https://gahr.box.com/shared/static/3olo4r7vsmcneq67edw6ih93f63hqq3j.jpeg"
const projectSVV = "https://gahr.box.com/shared/static/nj1cuw89mx3jjgl012ogtyowturb0cwh.jpg"

const historyImages = [
  { url: "https://gahr.box.com/shared/static/o55tk8kgigpb7vfq7hy0nvddoio7jypo.jpg", alt: "Winter at Sun Valley Lodge" },
  { url: "https://gahr.box.com/shared/static/qqv0xlqbzjrll7v7vaf8t0oexu78dcs5.jpg", alt: "Horsedrawn Sleigh at Challenger Inn" },
  { url: "https://gahr.box.com/shared/static/zfj3tnfu0rylem21fnszqp74b9q6a9fu.jpg", alt: "Wallace and Wright Golfing" },
  { url: "https://gahr.box.com/shared/static/yd4vqn8a7uo0yiozic2t8e37vd7emjf9.jpg", alt: "Womens Ski Team" }
];


function Home() {

  const [showProgressPop, setShowProgressPop] = useState(false);
  const togglePop = () => {
    setShowProgressPop(!showProgressPop)
  }

  return (
    <div className='home'>
      <div className='hero-image'>
        <div className='color-overlay'></div>
        <div className='hero-text'>
          <img src={titleImage} alt="explore the future of sun valley"></img>
        </div>
      </div>
      <div className="home-subheader" id="warm-springs">
        <h2>The Future of Sun Valley</h2>
        <div className="main-text">
          <p>The future is bright at Sun Valley Resort. Bringing out the best of our fan-favorite terrain while enhancing the guest experience from Village to base to peak, our multi-year improvement plan will preserve our world-class facilities and pave the way for new experiences and discoveries at the nation&apos;s first destination ski resort.</p>
        </div>
      </div>

      <div className="home-project">
        <h2 className="home-title-text">Explore Projects</h2>
        <div className="home-project-container">
          <a href='/projects/warmsprings'>
            <div className="home-project-list-box"><img src={projectWS} alt="warm springs project"></img></div>
            <div className="home-project-list-box-overlay">
              <h3>2023</h3>
              <h3>Warm Springs</h3>
            </div>
          </a>
          <a href='/projects/seattleridge'>
            <div className="home-project-list-box"><img src={projectSR} alt="seattle ridge project"></img></div>
            <div className="home-project-list-box-overlay">
              <h3>2024</h3>
              <h3>Seattle Ridge</h3>
            </div>
          </a>
          <a href='/projects/sunvalleyvillage'>
            <div className="home-project-list-box"><img src={projectSVV} alt="sun valley village"></img></div>
            <div className="home-project-list-box-overlay">
              <h3>Sun Valley Village</h3>
            </div>
          </a>
          <div>
            <button onClick={togglePop} className={showProgressPop ? 'display-none' : 'home-project-progress-link'}>PROGRESS UPDATES</button>
            {showProgressPop && (
              <div className="home-progress-pop">
                <a href='/progress/warmsprings'>Warm Springs Progress</a>
                <a href='/progress/seattleridge'>Seattle Ridge Progress</a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="home-highlights-container">
        <div className='home-highlights'>
          <div>
            <h2>The Vision</h2>
            <ul>
              <li><span className="bold">Elevate the Experience.</span> Through the construction of more-efficient lifts and by providing access to new and revitalized terrain, skiers and riders can expect the a more streamlined experience on the mountain.</li>
              <li><span className="bold">Support the Community.</span> As stewards of the beautiful terrain at the heart of our community, maintaining the health of our mountains is crucial to supporting the vibrancy of greater Sun Valley. The Bald Mountain Stewardship program works in conjunction with the National Forest Foundation, US Forest Service, and Bureau of Land Management to preserve the natural environment at the center of our community's vitality.</li>
              <li><span className="bold">Build for the Future.</span> With the replacement of aging lifts and by providing new experiences through greater access to on-mountain amenities, the legacy of Sun Valley is preserved for generations of skiers and riders to enjoy.</li>
              <li><span className="bold">Honor History.</span> As the nation's first destination ski resort, our multi-phase enhancement plan seeks to take forward-thinking strides while always honoring the storied past that makes our resort community unique.</li>
            </ul>
          </div>
          <div className="project-highlights-image">
            <img src={sunIcon} alt="sun valley sun icon"></img>
          </div>
        </div>
      </div>
      <div className="home-video">
        <video src={homeVideo} controls></video>
      </div>

      <div className="home-history">
        <h2 className="home-title-text">Steeped in History</h2>
        <p className="home-text">Established in 1936, as the first destination ski resort in North America, Sun Valley Resort continues to influence the ski industry, leaving a lasting imprint on the mountain west. Learn more about Sun Valley's storied history below.</p>
        <div className="home-history-image-container">
          <a href="/history">
            <img className="home-history-image" src={historyImages[0].url} alt={historyImages[0].alt}></img>
            <div className="home-history-image-overlay">
              <h4>Sun Valley's long and fabled history began in 1936 when Averell Harriman, the chairperson of the Union-Pacific Railroad, decided to establish the first destination ski resort in North America.</h4>
              <img src={sunIcon} alt="sun valley sun logo"></img>
            </div>
          </a>
          <div className="home-history-overlay-mobile">
            <h4>Sun Valley's long and fabled history began in 1936 when Averell Harriman, the chairperson of the Union-Pacific Railroad, decided to establish the first destination ski resort in North America.</h4>
          </div>
          <a href="/history">
            <img className="home-history-image" src={historyImages[1].url} alt={historyImages[1].alt}></img>
            <div className="home-history-image-overlay">
              <h4>One year after the Resort's opening, the Challenger Inn was built. Now known as the Sun Valley Inn, it is home to the historic restaurant with a charming Austrian atmosphere, The Ram.</h4>
              <img src={sunIcon} alt="sun valley sun logo"></img>
            </div>
          </a>
          <div className="home-history-overlay-mobile">
            <h4>One year after the Resort's opening, the Challenger Inn was built. Now known as the Sun Valley Inn, it is home to the historic restaurant with a charming Austrian atmosphere, The Ram.</h4>
          </div>
          <a href="/history">
            <img className="home-history-image" src={historyImages[2].url} alt={historyImages[2].alt}></img>
            <div className="home-history-image-overlay">
              <h4>Beginning in the summer of 1937, Sun Valley became a year-round destination, offering various activities such as golf, archery and shooting, biking, horse-back riding, ice skating.</h4>
              <img src={sunIcon} alt="sun valley sun logo"></img>
            </div>
          </a>
          <div className="home-history-overlay-mobile">
            <h4>Beginning in the summer of 1937, Sun Valley became a year-round destination, offering various activities such as golf, archery and shooting, biking, horse-back riding, ice skating.</h4>
          </div>
          <a href="/history">
            <img className="home-history-image" src={historyImages[3].url} alt={historyImages[3].alt}></img>
            <div className="home-history-image-overlay">
              <h4>The challenging terrain of Bald Mountain and the ideal learning terrain of Dollar Mountain have and continue to foster the talents of the best ski racers and snowboarders in the country.</h4>
              <img src={sunIcon} alt="sun valley sun logo"></img>
            </div>
          </a>
          <div className="home-history-overlay-mobile">
            <h4>The challenging terrain of Bald Mountain and the ideal learning terrain of Dollar Mountain have and continue to foster the talents of the best ski racers and snowboarders in the country.</h4>
          </div>

        </div>
        <a className="home-history-button" href='/history'>LEARN MORE</a>
      </div>

      <div className="home-sv-container">
        <img src="https://gahr.box.com/shared/static/albj3du959ma6gefa5k5k1vpn6tctrtg.jpeg" alt="skier on groomed slope"></img>
        <div className="home-sv-link">
          <a href="https://www.sunvalley.com">
            <div className="home-sv-link-text">
              <h3>Experience the Magic of Sun Valley</h3>
              <p>Head over to SunValley.com and start planning your next adventure, where towering peaks set the stage for an unforgettable journey.</p>
            </div>
            <div className="home-sv-link-arrow">
              <svg width="26" height="14" viewBox="0 0 25 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.5303 6.53033C24.8232 6.23744 24.8232 5.76257 24.5303 5.46967L19.7574 0.696703C19.4645 0.403809 18.9896 0.403809 18.6967 0.696702C18.4038 0.989596 18.4038 1.46447 18.6967 1.75736L22.9393 6L18.6967 10.2426C18.4038 10.5355 18.4038 11.0104 18.6967 11.3033C18.9896 11.5962 19.4645 11.5962 19.7574 11.3033L24.5303 6.53033ZM-1.31134e-07 6.75L24 6.75L24 5.25L1.31134e-07 5.25L-1.31134e-07 6.75Z"></path>
              </svg>
            </div>
          </a>
        </div>
      </div>

      <div className="projects-igfeed">
        <h2 className="home-title-text">Keep Up to Date with @sunvalley</h2>
        <IGFeed />
      </div>
      <div className="learn-more" id="contact">
        <h2>Learn More</h2>
        <p className="home-text">Please reach out if you would like to learn more about future enhancements at Sun Valley Resort. For media inquiries or photo requests, visit <a href="https://www.sunvalley.com/media" target="blank" rel="noreferrer">sunvalley.com/media</a>.</p>
        <ul className="contacts">
          <li>Maria Brickman |  Brand Manager | <a href="mailto:mbrickman@sunvalley.com">mbrickman@sunvalley.com</a></li>
          <li>Jess Fiaschetti | President, Outside PR Agency | <a href="mailto:jess@outsidepr.com">jess@outsidepr.com</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
