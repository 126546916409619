import '../assets/css/IGFeed.css'


export default function IGFeed() {
  return (
    <div className="igfeed">
      <div className="igfeed-container">
        <script src="https://snapwidget.com/js/snapwidget.js"></script>
        <iframe className="snapwidget-widget" title="ig-feed" src="https://snapwidget.com/embed/1053002"></iframe>
      </div>
      <div className="igfeed-container-mobile">
        <script src="https://snapwidget.com/js/snapwidget.js"></script>
        <iframe className="snapwidget-widget" title="ig-feed" src="https://snapwidget.com/embed/1053005"></iframe>
      </div>
    </div>
  )
}