import React from 'react';
import { useState } from 'react';
import '../assets/css/ProjectGallery.css'

export const GalleryItem = ({ children, width }) => {
  return (
    <div className="gallery-item" style={{ width: width }}>
      {children}
    </div>
  );
}

const Gallery = ({ children }) => {

  const [activeImage] = useState(0);

  

  return (
    <div className="project-gallery">

      <div className="gallery-container">

        

        <div className="gallery">
          <div className="active-image" style={{ transform: `translateX(-${activeImage * 100}%)` }}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, { width: "100%" });
            })}
          </div>
        </div>

      </div>

      <div className="gallery-color-block">
        <h4>CONCEPTS</h4>
      </div>

    </div>
  );
}

export default Gallery;


// carousel reference / credit: https://medium.com/tinyso/how-to-create-the-responsive-and-swipeable-carousel-slider-component-in-react-99f433364aa0

