import HeaderSubPages from "../components/HeaderSubPages";
import '../assets/css/Progress.css'
import IGFeedWS from "../components/IGFeed-WarmSprings";
import data from "../data/progress-ws.json"



function ProgressWarmSprings() {

  return (
    <div className="progress">
      <div className="progress-header-ws">
        <HeaderSubPages />
      </div>
      <div className="progress-subheader">
        <h2>Warm Springs Project Progress</h2>
        <div className="main-text">
          <p>Opened on December 20, 2023, the Warm Springs Enhancement Project greatly improves skier circulation on the mountain, allowing skier traffic to flow over a wider area of skiable terrain. The upgrades improve lift quality, efficiency and sustainability and provide multiple options for accessing the mountain from the Warm Springs area. </p>
          <p>Check out the updates below to see the 35-week project come together!</p>
        </div>
      </div>


      <div className="progress-container">
        <div className="progress-line"></div>
        <div className="update-container">
          <h4>{data[0].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[0].title}</h3>
            <p>{data[0].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[0].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[1].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[1].title}</h3>
            <p>{data[1].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[1].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[2].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[2].title}</h3>
            <p>{data[2].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[2].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[3].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[3].title}</h3>
            <p>{data[3].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[3].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[4].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[4].title}</h3>
            <p>{data[4].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[4].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[5].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[5].title}</h3>
            <p>{data[5].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[5].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[6].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[6].title}</h3>
            <p>{data[6].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[6].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[7].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[7].title}</h3>
            <p>{data[7].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[7].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[8].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[8].title}</h3>
            <p>{data[8].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[8].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[9].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[9].title}</h3>
            <p>{data[9].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[9].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[10].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[10].title}</h3>
            <p>{data[10].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[10].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[11].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[11].title}</h3>
            <p>{data[11].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[11].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[12].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[12].title}</h3>
            <p>{data[12].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[12].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[13].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[13].title}</h3>
            <p>{data[13].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[13].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[14].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[14].title}</h3>
            <p>{data[14].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[14].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[15].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[15].title}</h3>
            <p>{data[15].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[15].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[16].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[16].title}</h3>
            <p>{data[16].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[16].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[17].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[17].title}</h3>
            <p>{data[17].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[17].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[18].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[18].title}</h3>
            <p>{data[18].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[18].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[19].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[19].title}</h3>
            <p>{data[19].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[19].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[20].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[20].title}</h3>
            <p>{data[20].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[20].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
        <div className="update-container">
          <h4>{data[21].date}</h4>
          <div className="progress-line-bullet"></div>
          <div className="update-text">
            <h3>{data[21].title}</h3>
            <p>{data[21].text}</p>
          </div>
          <div className="update-video">
            <iframe src={data[21].media} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
      <div className="ig-feed-progress">
        <div className="igfeed-progress-title">
          <h4>Keep Up to Date with @sunvalley</h4>
        </div>
        <IGFeedWS />
      </div>
    </div>
  );
}

export default ProgressWarmSprings;