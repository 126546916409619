

function Future() {
  return (
    <div>
      <h2>future page content</h2>
    </div>
  );
}

export default Future;