import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import { slide as Menu } from 'react-burger-menu'
import { useState } from 'react'

import '../assets/css/Nav.css'

const logo = "https://gahr.box.com/shared/static/5s85qrkq5ns6sx3q23c5aybgnng53eqx.png"


export default function Nav() {

  const [isOpen, setOpen] = useState(false)
  const handleIsOpen = () => {
    setOpen(!isOpen)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const [showProjectsMenu, setShowProjectsMenu] = useState(false);
  const toggleProjects = () => {
    setShowProjectsMenu(!showProjectsMenu)
  }

  const [showProgressMenu, setShowProgressMenu] = useState(false);
  const toggleProgress = () => {
    setShowProgressMenu(!showProgressMenu)
  }

  return (
    <div className='nav'>
      <a href="https://www.sunvalley.com" target="blank" rel="noreferrer"><img src={logo} alt="sun valley logo" className="header-logo"></img></a>
      <div className='nav-desktop'>
        <Link to='/'>HOME</Link>
        <div className="nav-projects" onMouseLeave={toggleProjects}>
          <ul onMouseEnter={toggleProjects}>PROJECTS</ul>
          {showProjectsMenu && (
            <div className="nav-dropdown">
              <li><Link to='/projects/warmsprings'>2023 WARM SPRINGS</Link></li>
              <li><Link to='/projects/seattleridge'>2024 SEATTLE RIDGE</Link></li>
              <li><Link to='/projects/sunvalleyvillage'>SUN VALLEY VILLAGE</Link></li>
            </div>
          )}
        </div>
        <div className="nav-progress" onMouseLeave={toggleProgress}>
          <ul onMouseEnter={toggleProgress}>PROGRESS UPDATES</ul>
          {showProgressMenu && (
            <div className="nav-dropdown">
              <li><Link to='/progress/warmsprings'>2023 WARM SPRINGS</Link></li>
              <li><Link to='/progress/seattleridge'>2024 SEATTLE RIDGE</Link></li>
            </div>
          )}
        </div>
        <Link to='/history'>HISTORY</Link>
        <HashLink smooth to='/#contact'>CONTACT</HashLink>
      </div>
      <div>



        <Menu right className="nav-mobile"
          isOpen={isOpen}
          onOpen={handleIsOpen}
          onClose={handleIsOpen}
        >
          <div>
            <Link onClick={handleClose} to='/'>HOME</Link>
            <div
              className="nav-projects">
              <ul onClick={toggleProjects}>PROJECTS</ul>
              {showProjectsMenu && (
                <div className="nav-dropdown">
                  <li><Link onClick={handleClose} to='/projects/warmsprings'>2023 WARM SPRINGS</Link></li>
                  <li><Link onClick={handleClose} to='/projects/seattleridge'>2024 SEATTLE RIDGE</Link></li>
                  <li><Link to='/projects/sunvalleyvillage'>SUN VALLEY VILLAGE</Link></li>
                </div>
              )}
            </div>
            <div className="nav-progress">
              <ul onClick={toggleProgress}>PROGRESS</ul>
              {showProgressMenu && (
                <div className="nav-dropdown">
                  <li><Link onClick={handleClose} to='/progress/warmsprings'>2023 WARM SPRINGS</Link></li>
                  <li><Link onClick={handleClose} to='/progress/seattleridge'>2024 SEATTLE RIDGE</Link></li>
                </div>
              )}
            </div>
            <Link onClick={handleClose} to='/history'>HISTORY</Link>
            <HashLink onClick={handleClose} smooth to='/#contact'>CONTACT</HashLink>
          </div>
        </Menu>
      </div>
    </div>
  )
}