import React from 'react';
import { useState } from 'react';
import '../assets/css/ProjectGallery.css'

export const GalleryItem = ({ children, width }) => {
  return (
    <div className="gallery-item" style={{ width: width }}>
      {children}
    </div>
  );
}

const Gallery = ({ children }) => {

  const [activeImage, setActiveImage] = useState(0);

  const updateImage = (newImage) => {
    if (newImage < 0) {
      newImage = React.Children.count(children) - 1;
    } else if (newImage >= React.Children.count(children)) {
      newImage = 0;
    }
    setActiveImage(newImage);
  };

  return (
    <div className="project-gallery">

      <div className="gallery-container">

        <div className="gallery-buttons">
          <button
            onClick={() => {
              updateImage(activeImage - 1)
            }}
          >
            <span className="material-symbols-outlined">arrow_back_ios</span>
          </button>
          <button
            onClick={() => {
              updateImage(activeImage + 1)
            }}
          >
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>

        <div className="gallery">
          <div className="active-image" style={{ transform: `translateX(-${activeImage * 100}%)` }}>
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, { width: "100%" });
            })}
          </div>
        </div>

      </div>

      <div className="gallery-color-block">
        <h4>PROJECT PLANS</h4>
      </div>

    </div>
  );
}

export default Gallery;


// carousel reference / credit: https://medium.com/tinyso/how-to-create-the-responsive-and-swipeable-carousel-slider-component-in-react-99f433364aa0

