import '../assets/css/HeaderSubPages.css'
import { useLocation } from 'react-router-dom'

const headerText = {
  history: "https://gahr.box.com/shared/static/mkta1f4uc25xgqw73hbxblntoj2cx5tx.png",
  warmsprings: "https://gahr.box.com/shared/static/wqbqxpn7uei595q4j69pj8jwtjhayd09.png",
  seattleridge: "https://gahr.box.com/shared/static/5cbkema186crk4m7wcdcmhhar87ety1h.png"
}
const headerTextProjects = {
  warmsprings: "https://gahr.box.com/shared/static/gmwxw75sqbyzg1u8u85prquuu4j8voxg.png",
  seattleridge: "https://gahr.box.com/shared/static/un373ijkcoirfi8vx41dg5iv0bmhffnk.png",
  sunvalleyvillage: "https://content-us-1.content-cms.com/80855038-a47d-48dc-abc6-88e722a8365f/dxdam/61/61e3d664-c789-4c38-b074-b5f36f6c9b08/Project_SunValleyVillagev2.png"
}

export default function HeaderSubPages() {
  const path = useLocation().pathname;
  const location1 = path.split("/")[1];
  const location2 = path.split("/")[2];

  if (location1 === "projects") {
    return (
      <div className='headersubpages'>
        <div className='hero-image'>
          <div className='color-overlay'></div>
          <div className='hero-text'>
            <img src={headerTextProjects[location2]} alt="explore the future of sun valley"></img>
          </div>
        </div>
      </div>
    );
  }
  else if (location1 === "history") {
    return (
      <div className='headersubpages'>
        <div className='hero-image'>
          <div className='color-overlay'></div>
          <div className='hero-text'>
            <img src={headerText.history} alt="explore the future of sun valley"></img>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='headersubpages'>
      <div className='hero-image'>
        <div className='color-overlay'></div>
        <div className='hero-text'>
          <img src={headerText[location2]} alt="explore the future of sun valley"></img>
        </div>
      </div>
    </div>
  );
}