import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'

import '../assets/css/Footer.css'

const logo = "https://gahr.box.com/shared/static/irt01wbhpqx1ny8p7anstb60kd3sacnd.png"

export default function Footer() {
  return (
    <div className='footer'>
      <div className='footer-left'>
        <Link to='/'>Home</Link>
        <Link to='/projects/warmsprings'>2023 Warm Springs Project</Link>
        <Link to='/projects/seattleridge'>2024 Seattle Ridge Project</Link>
        <Link to='/projects/sunvalleyvillage'>Sun Valley Village</Link>
        <Link to='/history'>History</Link>
        <HashLink smooth to='/#contact'>Contact</HashLink>
      </div>
      <div className='footer-right'>
        <a href="https://www.sunvalley.com" target="blank" rel="noreferrer">
          <img src={logo} alt="sun valley logo"></img>
        </a>
        <a href="https://www.sunvalley.com" target="blank" rel="noreferrer">sunvalley.com</a>
      </div>
    </div>
  )
}